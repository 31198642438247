import React, { useState } from 'react'
import Select from 'react-select'
import { Link } from 'gatsby'

function RegisterForm({submit}) {
  const [ selectedOption, setSelectedOption ] = useState(null)

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption)
  }

  const selectOptions = [
    { value: 'jedan', label: 'Jedan' },
    { value: 'dva', label: 'Dva' },
    { value: 'tri', label: 'Tri' },
  ]

  return (
    <div className="form-wrapper register-form">
      <form method="post" action="#">
        <input type="text" placeholder="First name *" name="FirstName" id="FirstName" required />
        <input type="text" placeholder="Last name *" name="LastName" id="LastName" required />
        <input type="text" placeholder="E-mail *" name="Email" id="Email" required />
        <input type="text" placeholder="Phone number" name="PhoneNumber" id="PhoneNumber" />
        <input type="password" placeholder="Password *" name="Password" id="Password" required />
        <input type="password" placeholder="Confirm Password *" name="ConfirmPassword" id="ConfirmPassword" required />
        <input type="text" placeholder="Company *" name="Company" id="Company" required />
        <Select 
          value={selectedOption}
          name="Position"
          placeholder="Select position *"
          options={selectOptions}
          onChange={handleChange}
        />
        <div className="submit-wrap">
          <Link to="/KeyVerification"><button type="submit">{submit}</button></Link>
        </div>
      </form>
    </div>

  )
}

export default RegisterForm