import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import HalfHero from '../components/half-hero'
import RegCircles from '../components/reg-circles'
import FormRegister from '../components/form-register'

const Page = () => {
  const data = useStaticQuery(graphql`
    query registration {
      tc: markdownRemark(fields: { parent: { relativeDirectory: { eq: ""}, name: {eq: "registration"}}}) {
        html
        frontmatter {
          title
          keyExists
          step
          button
        }
      }
    }
  `)

  const { tc } = data

  return (
    <>
      <SEO title="Terms and Conditions" />

      <HalfHero fullWidth />

      <section style={{marginTop: '2rem'}}>

        <h1>{tc.frontmatter.title}</h1>

        <RegCircles active="2" />

        <div className="text-center">
          <div>
            <Link to="/KeyVerification"><button className="white">{tc.frontmatter.keyExists}</button></Link>
          </div>

          <div>{tc.frontmatter.step}</div>

          <div 
            dangerouslySetInnerHTML={{ __html: tc.html }}
          />

          <FormRegister submit={tc.frontmatter.button} />
        </div>


      </section>
    </>
  )
}

export default Page